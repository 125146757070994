<template>
    <v-row v-if="product">
        <v-col class="d-flex align-center justify-center" cols="4" sm="3" md="2" lg="1">
            <div v-if="product.previewPicture">
                <img height="56" :src="product.previewPicture" :alt="product.name" :title="product.name" />
            </div>
            <div v-else>
                <AppVIcon icon="breadLoaf" size="56" class="grey--text text--lighten-2"/>
            </div>
        </v-col>

        <v-col cols="8" sm="9" md="10" lg="11">
            <v-row align="center">
                <v-col class="py-0" cols="12" md="6" lg="4">
                    <div class="subtitle-1">{{ product.name }}</div>
                    <v-row align="center">
                        <v-col class="py-1" cols="auto">
                            <span class="headline primary--text">{{ product.price | currency }}</span>
                        </v-col>
                        <v-col class="py-1" cols="auto">
                            <span class="body-2 grey--text">{{ product.weight }} г.</span>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col class="py-1" cols="12" md="6" lg="8">
                    <AppVCounter :quantity.sync="quantityComputed"/>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<script>
    import {
        VRow,
        VCol,
    } from 'vuetify/lib'
    import AppVIcon from '@/components/vuetify/Icon'
    import AppVCounter from '@/components/app/Counter'

    import { mapMutations, mapGetters } from 'vuex'

    export default {
        name: 'ProductItem',

        components: {
            AppVCounter,

            VRow,
            VCol,

            AppVIcon,
        },

        props: {
            product: { type: Object, default: null },
        },

        data() {
            return {}
        },

        computed: {
            ...mapGetters({
                getQuantity: 'product/getSelectedQuantity'
            }),

            quantityComputed: {
                get() {
                    return this.product ? this.getQuantity(this.product.id) : 0
                },
                set(quantity) {
                    this.setQuantity({ productId: this.product.id, quantity })
                },
            },
        },

        methods: {
            ...mapMutations({
                setQuantity: 'product/setSelectedQuantity',
            }),
        },
    }
</script>
