<template>
    <div v-if="products && products.length" class="product-list">
        <template v-for="group in groupsComputed">
            <v-subheader :key="`groupSubheader_${group.id}`">{{ group.name }}</v-subheader>

            <v-container :key="`groupProductList_${group.id}`" class="py-0" fluid>
                <template v-for="product in group.products">
                    <ProductItem
                        :key="`groupProduct_${group.id}_${product.id}`"
                        :product="product"
                    />
                </template>
            </v-container>
        </template>
    </div>
</template>

<script>
    import {
        VContainer,

        VSubheader,
    } from 'vuetify/lib'

    import ProductItem from '@/components/app/product/ProductItem'

    import {
        cloneDeep as _cloneDeep,
        filter as _filter,
        forEach as _forEach,
        groupBy as _groupBy,
    } from 'lodash'

    export default {
        name: 'ProductList',

        components: {
            VContainer,

            VSubheader,

            ProductItem,
        },

        props: {
            products: { type: Array, default: null },
            groups: { type: Array, default: null },
        },

        data() {
            return {}
        },

        computed: {
            productsGroupedByGroupId() {
                return _groupBy(_cloneDeep(this.products), 'groupId')
            },

            groupsComputed() {
                let groups = _cloneDeep(this.groups)

                _forEach(groups, Group => {
                    Group.products = this.productsGroupedByGroupId[Group.id] || []
                })

                return _filter(groups, Group => {
                    return Group.products.length
                })
            },
        },
    }
</script>
