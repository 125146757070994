<template>
    <div class="counter">
        <div class="counter__prepend">
            <v-btn
                class="pa-0"
                color="primary"
                :disabled="quantityComputed < 1"
                small
                @click="quantityComputed--"
            >
                -
            </v-btn>
        </div>
        <v-text-field
            v-model="quantityComputed"
            inputmode="numeric"
            min="0"
            pattern="[0-9]*"
            solo
            value="9999"
        />
        <div class="counter__append">
            <v-btn
                class="pa-0"
                color="primary"
                small
                @click="quantityComputed++"
            >
                +
            </v-btn>
        </div>
    </div>
</template>

<script>
    import {
        VBtn,

        VTextField,
    } from 'vuetify/lib'

    export default {
        name: 'Counter',

        components: {
            VBtn,

            VTextField,
        },

        props: {
            quantity: { type: Number, default: 0 }
        },

        data() {
            return {}
        },

        computed: {
            quantityComputed: {
                get() {
                    return this.quantity
                },
                set(quantity) {
                    this.$emit('update:quantity', Number(quantity))
                },
            },
        },
    }
</script>
