<template>
    <v-icon
        v-if="icon"
        v-text="iconComputed"
        :color="iconColor"
        :small="small"
        :medium="medium"
        :large="large"
        :size="size"
    />
</template>

<script>
    import { VIcon } from 'vuetify/lib'

    export default {
        name: 'Icon',

        components: {
            VIcon,
        },

        props: {
            icon: { type: String, default: null },
            color: { type: String, default: null },
            small: { type: [String, Boolean], default: null },
            medium: { type: [String, Boolean], default: null },
            large: { type: [String, Boolean], default: null },
            size: { type: [Number, String, Boolean], default: null },
        },

        data() {
            return {}
        },

        computed: {
            iconComputed() {
                return '$vuetify.icons.' + this.icon
            },

            iconColor() {
                return this.color
            }
        },
    }
</script>
